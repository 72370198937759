@font-face {
font-family: '__proximaNova_100f2e';
src: url(/_next/static/media/588fcd960b294ca4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__proximaNova_100f2e';
src: url(/_next/static/media/9b213503bf9c7d0e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: '__proximaNova_100f2e';
src: url(/_next/static/media/531430a2ffce903b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__proximaNova_100f2e';
src: url(/_next/static/media/6738ce5ec8ea0f4a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__proximaNova_100f2e';
src: url(/_next/static/media/319e2744310dc271-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__proximaNova_Fallback_100f2e';src: local("Arial");ascent-override: 108.90%;descent-override: 32.82%;line-gap-override: 0.00%;size-adjust: 99.09%
}.__className_100f2e {font-family: '__proximaNova_100f2e', '__proximaNova_Fallback_100f2e'
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Proxima Nova';
  background: #fafafa;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* CeraPro */
@font-face {
  font-family: 'CeraPro';
  src:
    url(/_next/static/media/CeraPro-Bold.0d192fab.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'CeraPro';
  src:
    url(/_next/static/media/CeraPro-Regular.6ab09ae0.woff) format('woff');
  font-weight: normal;
}

.app {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -ms-flex-direction: column;
      flex-direction: column;
  background: #ffffff;
}

/*  React cropper css */
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled,
.ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__image {
  display: block;
  max-width: 100%;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: move;
  -webkit-box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  border: 1px solid #ff1c33;
  border-image-slice: 1;
  border-image-repeat: repeat;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: calc(100% / 3);
}

.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: calc(100% / 3 * 2);
}

.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: calc(100% / 3);
}

.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: calc(100% / 3 * 2);
}

.ReactCrop__drag-handle {
  position: absolute;
}

.ReactCrop__drag-handle::after {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: 1px solid transparent;
}

.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}

.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}

.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}

.ReactCrop .ord-n::after {
  top: 0;
}

.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}

.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}

.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}

.ReactCrop .ord-e::after {
  right: 0;
}

.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}

.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}

.ReactCrop .ord-s::after {
  bottom: 0;
}

.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}

.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}

.ReactCrop .ord-w::after {
  left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

@-webkit-keyframes anime-trans {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes anime-trans {
	from { opacity: 0; }
	to { opacity: 1; }
}

.anime {
	-webkit-animation: anime-trans 0.6s;
	        animation: anime-trans 0.6s;
}

.thumb_svg_icon {  -webkit-transform: scale(3);  transform: scale(3) }
.admin_svg_icon { -webkit-transform: scale(2); transform: scale(2) }

.MuiPickersDatePickerRoot-toolbar {
  padding-top: 10px;
}

.MuiPickersDatePickerRoot-toolbar .MuiPickersToolbarButton-toolbarBtn .MuiTypography-h4 {
  padding-top: 0;
  padding-bottom: 10px;
}
